export const validateEmail = (email) => {
    const match = /\S+@\S+\.\S+/;
    return match.test(email);
}

export const validatePhoneNumber = (number) => {
    const clearedNumber = number.replace('(', '').replace(')', '').replace('-', '').replace(/\s/g, '').trim();
    const phoneRegex = /^(1\s|1|)?((\(\d{3}\))|\d{3})(-|\s)?(\d{3})(-|\s)?(\d{4})$/;
    return phoneRegex.test(clearedNumber);
}

export const validateStrippedPhoneNumber = (number) => {
    // validate whether a number contains only ten digits and no special symbols
    const onlyDigitsRegex = /^\d+$/;
    
    return number.length === 10 && onlyDigitsRegex.test(number);
}

export const validateWholeNumbers = (number) => {
    return Number.isInteger(Number(number)) && number > 0;
}
export const fileTypeChecker = async (file) => new Promise((resolve) => {
    const fileReader = new FileReader()
    fileReader.readAsArrayBuffer(file.slice(0, 4));
    fileReader.onloadend = (e) => {
      const arr = (new Uint8Array(e.target.result)).subarray(0, 4)
      let header = ''
      let type = null
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16)
      }
      console.log(header);
      switch (header) {
        case '89504e47': // png
        case 'ffd8ffe0': // following are jpeg magic numbers
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
          type = 'IMG';
          break;            
        default:
          type = 'unknown'; 
          break;
      }
      resolve(type);
    }     

});
